// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dwD97otwi", "M6ZzyvYPC", "Vrk4QvIQN", "JOCcIqs83", "adtNjNCbn"];

const serializationHash = "framer-tj04u"

const variantClassNames = {adtNjNCbn: "framer-v-yoc0s8", dwD97otwi: "framer-v-1wavxud", JOCcIqs83: "framer-v-1wlc6io", M6ZzyvYPC: "framer-v-xy8nk5", Vrk4QvIQN: "framer-v-1jbqj0y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {delay: 0, duration: 5, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "dwD97otwi", "Variant 2": "M6ZzyvYPC", "Variant 3": "Vrk4QvIQN", "Variant 4": "JOCcIqs83", "Variant 5": "adtNjNCbn"}

const getProps = ({height, id, radius, width, ...props}) => { return {...props, nxLcUwaZG: radius ?? props.nxLcUwaZG, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dwD97otwi"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;radius?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nxLcUwaZG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dwD97otwi", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearf382t5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2000)
})

useOnVariantChange(baseVariant, {default: onAppearf382t5})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.figure {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wavxud", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dwD97otwi"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: radiusForCorner(nxLcUwaZG, 3), borderBottomRightRadius: radiusForCorner(nxLcUwaZG, 2), borderTopLeftRadius: radiusForCorner(nxLcUwaZG, 0), borderTopRightRadius: radiusForCorner(nxLcUwaZG, 1), ...style}} tabIndex={-1} {...addPropertyOverrides({adtNjNCbn: {"data-framer-name": "Variant 5"}, JOCcIqs83: {"data-framer-name": "Variant 4"}, M6ZzyvYPC: {"data-framer-name": "Variant 2"}, Vrk4QvIQN: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tj04u.framer-1xjr716, .framer-tj04u .framer-1xjr716 { display: block; }", ".framer-tj04u.framer-1wavxud { height: 300px; overflow: hidden; position: relative; width: 600px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"M6ZzyvYPC":{"layout":["fixed","fixed"]},"Vrk4QvIQN":{"layout":["fixed","fixed"]},"JOCcIqs83":{"layout":["fixed","fixed"]},"adtNjNCbn":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nxLcUwaZG":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYiBnw9609: React.ComponentType<Props> = withCSS(Component, css, "framer-tj04u") as typeof Component;
export default FramerYiBnw9609;

FramerYiBnw9609.displayName = "Component / Colour Change";

FramerYiBnw9609.defaultProps = {height: 300, width: 600};

addPropertyControls(FramerYiBnw9609, {variant: {options: ["dwD97otwi", "M6ZzyvYPC", "Vrk4QvIQN", "JOCcIqs83", "adtNjNCbn"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, nxLcUwaZG: {defaultValue: 0, title: "Radius", type: ControlType.Number}} as any)

addFonts(FramerYiBnw9609, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})